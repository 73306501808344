@font-face {
  font-display: swap;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url('../webfonts/montserrat-v25-latin-regular.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: url('../webfonts/montserrat-v25-latin-600.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Bitter';
  font-style: italic;
  font-weight: 300;
  src: url('../webfonts/bitter-v32-latin-italic.woff2') format('woff2');
}

body {
  color: $base;
}

h1,h2,h3,h4,h5,h5 {
  font-style: italic;
}
h1 {
  border-left: 3px solid $white;
  padding-left: 20px;
  font-size: 6vw;
  @include media-breakpoint-up(md) {
    font-size: 5.5vw;
  }
  @include media-breakpoint-up(lg) {
    font-size: 4vw;
  }

}
.h1sub {
  display: block;
  @include media-breakpoint-up(md) {
    font-size: 3vw;
  }
  @include media-breakpoint-up(lg) {
    font-size: 2vw;
  }

}

p {

  &:last-child {
    margin-bottom: 0;
  }
}

.column-2 {
  column-count: 2;
}

//Link Decorators
.ld-btn-highlight {
  @extend .btn;
  background-color: $primary;
  color: $white;
  &:hover {
    background-color: shade-color($primary, 30%);
    color: $white;
  }
  &:active {
    background-color: shade-color($primary, 50%) !important;
    color: $white !important;
  }
}
.ld-btn-subtle {
  @extend .btn;
  @extend .btn-outline-primary;
  &:hover {
    color: $white;
  }
  &:active {
    color: $white !important;
  }
}

.bg-primary {
  h1, h2, h3, h4, h5, h6, p, a, li {
    color: $white;
  }
  .ld-btn-highlight {
    background-color: $white;
    color: $primary;
    &:hover {
      color: shade-color($primary, 30%);
    }
    &:active {
      background-color: $white !important;
      color: shade-color($primary, 50%) !important;
    }
  }
  .ld-btn-subtle {
    border-color: $white;
    &:hover {
      background-color: shade-color($primary, 30%);
    }
    &:active {
      background-color: shade-color($primary, 50%) !important;
      color: $white !important;
    }
  }
}
.bg-secondary {
  h1, h2, h3, h4, h5, h6, p, a, li {
    color: $white;
  }
  .ld-btn-highlight {
    background-color: $white;
    color: $secondary;
    &:hover {
      color: shade-color($secondary, 30%);
    }
    &:active {
      background-color: $white !important;
      color: shade-color($secondary, 50%) !important;
    }
  }
  .ld-btn-subtle {
    border-color: $white;
    &:hover {
      background-color: shade-color($secondary, 30%);
    }
    &:active {
      border-color: transparent !important;
      background-color: shade-color($secondary, 50%) !important;
      color: $white !important;
    }
  }

}

table {
  border: $superlight solid 1px;
  tr {
    border: $superlight solid 1px;
    &:nth-child(even) {
      background: $superlight;
    }
  }
    th {
      background: $light;
      color: $primary;
      padding: 5px 10px;
      border: $superlight solid 1px;
    }
  td {
    padding: 5px 10px;
    border: $superlight solid 1px;
  }
}

.table {
  width: 100%;
  overflow-y: auto;
}
