.hero {
  position: relative;
}
.heroBackground {
  position: absolute;
}
.heroContent {
  position: absolute;
  background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.5) 60%, rgba(255,255,255,0) 100%);
  bottom: 0;
  left: 0;
}