$height-values: (25, 50, 75);
$breakpoints: sm, md, lg, xl;

@each $value in $height-values {
  .vh-#{$value} {
    height: #{$value}vh;
  }
  .vh-min-#{$value} {
    min-height: #{$value}vh;
  }

  @each $breakpoint in $breakpoints {
    @include media-breakpoint-up(#{$breakpoint}) {
      .vh-#{$breakpoint}-#{$value} {
        height: #{$value}vh;
      }
      .vh-min-#{$breakpoint}-#{$value} {
        min-height: #{$value}vh;
      }
    }
  }
}