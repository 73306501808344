//functionality
#navigationMain {
  display: none;
}
@include media-breakpoint-up(lg) {
  #navigationMain {
    display: block !important;
  }
  .navigationList, .navigationList * {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .navigationList li {
    position: relative;
    white-space: nowrap;
  }
  .navigationList ul {
    position: absolute;
    visibility: hidden;
    top: 100%;
    left: 0;
    z-index: 9999;
  }
  .navigationList > li {
    display: inline-block;
  }

  .navigationList li:hover > ul,
  .navigationList li.sfHover > ul {
    visibility: visible;
  }
  .navigationList a {
    display: block;
    position: relative;
    text-decoration: none;
  }
  .navigationList ul ul {
    top: 0;
    left: 100%;
  }
}
