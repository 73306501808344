//styling
#navigationMain {
  ul {
    background: $light;
    transition: visibility 0.1s ease;
    li {
      &:hover {
        background: $medium;
        box-shadow: inset 5em 0 0 0 $medium;
      }
      &:hover > ul {
        transition: visibility 0.5s ease;
      }

    }
  }
  a {
    color: $secondary;
    padding: 10px 5px;
  }
  li li a {
    padding: 10px;
  }
  .active {
    background: $medium;
  }

}