//colors
$primary: #7D8F38;
$secondary: #683C11;
$dark: #878787;
$base: #575756;
$medium: #dadada;
$light: #ededed;
$superlight: #f5f5f5;
$white: #fff;
$warning: #c78000;
$theme-colors: (
        "primary": $primary,
        "secondary": $secondary,
        "dark": $dark,
        "medium": $medium,
        "light": $light,
        "base": $base,
        "warning": $warning,
        "white": $white
);

$headings-color: $primary;


//typo
$font-family-base : Montserrat, Arial, sans-serif;
$font-weight-bold: 600;
$headings-font-weight: 300;
$headings-font-family: Bitter, Palatino, serif;

$font-size-base:              1.125rem;
$font-size-sm:                $font-size-base * .875;
$font-size-lg:                $font-size-base * 10.25;

$h2-font-size:                $font-size-base * 2;
$h3-font-size:                $font-size-base * 1.5;
$h4-font-size:                $font-size-base * 1.2;


$line-height-base:            1.7;

