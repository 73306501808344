.mm-menu {
  .mm-listitem {
    border-color: $light !important;
  }
  .mm-btn--next::after, .mm-btn--prev::before {
    border-color: $secondary !important;
  }
  .mm-navbar__title {
    font-weight: bold;
  }
  a {
    color: $secondary !important;
  }
  .active {
    background-color: $light;
  }
}